import confetti from "canvas-confetti";
import React, { FC, useEffect, useMemo, useState } from "react";
import CurrencyFormat from "react-currency-format";
import done from "@/style/img/done_gold.svg";
import Step2Row, { InvoiceLine } from "./InvoiceTableStep2Row";
import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";
import { gql, useMutation } from "@apollo/client";
import { useSettingsStore } from "@/zustand/useSettingsStore";
import { MCO } from "@/types";

type Props = {
  invoiceLines: any[];
  recentlyApproved: boolean;
  mco: MCO;
};

const REOPEN_MCO = gql`
  mutation ReopenMaintenanceCustomerOrderLine(
    $maintenanceCustomerOrderNumber: String!
    $maintenanceCustomerOrderLineNumber: String!
    $issuedBy: String!
  ) {
    reopenMaintenanceCustomerOrderLine(
      maintenanceCustomerOrderNumber: $maintenanceCustomerOrderNumber
      maintenanceCustomerOrderLineNumber: $maintenanceCustomerOrderLineNumber
      issuedBy: $issuedBy
    )
  }
`;

const playSound = () => {
  const soundPath = `${window.location.origin}/ka-ching.mp3`;
  const audio = new Audio(soundPath);
  audio.play();
};

function fire(particleRatio, opts) {
  const count = 200;
  const defaults = {
    origin: { y: 0.7, x: 0.62 },
  };
  confetti({
    ...defaults,
    ...opts,
    particleCount: Math.floor(count * particleRatio),
  });
}
function success() {
  playSound();

  setTimeout(() => {
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 70,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });

    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  }, 700);
}
const InvoiceTableStep3: FC<Props> = ({
  invoiceLines,
  recentlyApproved = false,
  mco,
}) => {
  const [awaitingReopen, setAwaitingReopen] = useState<boolean>(false);
  const [reopenMco] = useMutation(REOPEN_MCO);
  const handleReopenMco = async () => {
    setAwaitingReopen(true);
    const { data } = await reopenMco({
      variables: {
        maintenanceCustomerOrderNumber: mco.referenceOrderNumber,
        maintenanceCustomerOrderLineNumber: mco.referenceOrderLine,
        issuedBy: useSettingsStore.getState().userData.mail,
      },
    });
  };
  const sortedInvoiceLines = useMemo(() => {
    if (!Array.isArray(invoiceLines)) {
      return [];
    }
    const sortedLines = [...invoiceLines].sort((a, b) => {
      if (a.itemName < b.itemName) {
        return -1;
      }
      if (a.itemName > b.itemName) {
        return 1;
      }
      return 0;
    });
    console.debug("step 2 lines", sortedLines);
    return sortedLines;
  }, [invoiceLines]);

  useEffect(() => {
    if (recentlyApproved) {
      success();
    }
  }, [recentlyApproved]);

  return (
    <div className={styles.tableStep1}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={`${styles.successArea} ${styles.recentlyApproved}`}
        onClick={success}
      >
        <img
          src={done}
          alt="Process finished"
          style={{
            margin: "2px 15px -4px 3px",
            width: "30px",
            height: "30px",
            verticalAlign: "bottom",
          }}
        />
        The invoice has been approved!
      </div>
      <div className={styles.section}>
        <div className={`${styles.gridRow2} ${styles.headers}`}>
          <div style={{ marginLeft: "15px" }}>Operation</div>
          <div>Description</div>
          <div>Transaction date</div>
          <div>Quantity</div>
          <div>Price per unit</div>
          <div>Sales price</div>
          <div>Discount percentage</div>
          <div>Total sales price</div>
        </div>
      </div>
      {sortedInvoiceLines.map((line) => {
        return (
          <Step2Row readOnly key={line.id} invoiceLine={line as InvoiceLine} />
        );
      })}
      <div className={`${styles.totalSection}`}>
        <CurrencyFormat
          value={sortedInvoiceLines
            .reduce((accumulator, line) => {
              return accumulator + line.totalSalesPrice;
            }, 0)
            .toFixed(2)}
          displayType="text"
          thousandSeparator
          prefix="NOK "
          renderText={(value) => (
            <div style={{ paddingTop: 12, color: "black" }}>{value}</div>
          )}
        />
      </div>
      <br />
      {/* To be reinstated when  backend can do sufficient checks on MCO/billing status*/}
      {/* <button
        type="button"
        className={`${textStyles.primaryText} ${styles.invoiceWizardButton} `}
        style={{ float: "right" }}
        disabled={awaitingReopen}
        onClick={() => {
          handleReopenMco();
        }}
      >
        Reopen MCO
      </button> */}
    </div>
  );
};

export default InvoiceTableStep3;
