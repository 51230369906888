import { gql, useMutation, useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import React, { FC, useEffect, useMemo, useState } from "react";
import WorkOrderLine from "./WorkOrderLine";
import CircularLoading from "@/components/common/circularLoading";
import {
  getOrderById,
  mergeOrderIntoCollections,
} from "@/zustand/useOrderStore";
import { captureMessage } from "@/helpers/captureError";
import clockBig from "@/style/img/clockBig.svg";
// import syncIcon from "@/style/img/sync.svg";
import warningIcon from "@/style/img/warning.svg";
import { WorkOrderLineTypes } from "@/types";
import { useUserData } from "@/zustand/useSettingsStore";

import { customOrderLinesSort } from "../operationLines/OperationLinesList";
import { CostingType, LaborCode } from "./InvoiceTableStep1Row";

import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";
import { FINISHED_ORDER_LINE_THRESHOLD } from "@/helpers/constants";

type Props = {
  workOrderId: string;
  waitingReview: boolean;
  errorMessage: string;
  orderLines: WorkOrderLineTypes[];
  setOrderLines: (WorkOrderLineTypes) => void;
  goToStep: (number) => void;
};

const GET_ALL_COSTING_TYPES = gql`
  query getCostingTypes {
    costingTypes {
      id
      name
      description
    }
  }
`;

const GET_ALL_LABOR_CHARGE_CODES = gql`
  query getLaborChargeCodes {
    laborChargeCodes {
      id
      name
      description
    }
  }
`;
//This now handles closing workorders if all lines have been approved.
const APPROVE_INVOICE_STEP_1 = gql`
  mutation closeWorkOrder($workOrderNumber: String!, $issuedBy: String!) {
    closeWorkOrder(workOrderNumber: $workOrderNumber, issuedBy: $issuedBy)
  }
`;

//This now handles closing workOrders in the backend when all orderlines are approved.
const APPROVE_WORKORDER_LINE = gql`
  mutation ApproveWorkOrderLine(
    $workOrderNumber: String!
    $workOrderLineNumber: String!
    $issuedBy: String!
  ) {
    approveWorkOrderLine(
      workOrderNumber: $workOrderNumber
      workOrderLineNumber: $workOrderLineNumber
      issuedBy: $issuedBy
    )
  }
`;

const REOPEN_WORKORDER_LINE = gql`
  mutation ReopenWorkOrderLine(
    $workOrderNumber: String!
    $workOrderLineNumber: String!
    $issuedBy: String!
  ) {
    reopenWorkOrderLine(
      workOrderNumber: $workOrderNumber
      workOrderLineNumber: $workOrderLineNumber
      issuedBy: $issuedBy
    )
  }
`;

const InvoiceTableStep1: FC<Props> = ({
  workOrderId,
  goToStep,
  orderLines,
  setOrderLines,
  waitingReview,
  errorMessage,
}) => {
  const [allCostingTypes, setAllCostingTypes] = useState<CostingType[]>([]);
  const [allLaborCodes, setAllLaborCodes] = useState<LaborCode[]>([]);
  const [approveWorkorderLine] = useMutation(APPROVE_WORKORDER_LINE);
  const [approveInvoice] = useMutation(APPROVE_INVOICE_STEP_1);
  const [reopenWorkorderLine] = useMutation(REOPEN_WORKORDER_LINE);
  const [linesForApproval, setLinesForApproval] = useState<string[]>([]);
  const [areAllLinesSelectedForApproval, setAreAllLinesSelectedForApproval] =
    useState<boolean>(false);
  const [awaitingLineApproval, setAwaitingLineApproval] =
    useState<boolean>(false);
  const userData = useUserData();

  function checkIfAllLinesAreApproved() {
    return orderLines.every((line) => {
      return parseInt(line.status) > FINISHED_ORDER_LINE_THRESHOLD;
    });
  }

  useEffect(() => {
    if (checkIfAllLinesAreApproved()) {
      return setAwaitingLineApproval(false);
    }

    const allApprovalAttemptsCompleted = !orderLines.some((line) => {
      line.status === FINISHED_ORDER_LINE_THRESHOLD.toString();
    });

    if (allApprovalAttemptsCompleted) {
      return setAwaitingLineApproval(false);
    }
  }, [orderLines]);

  const checkboxes: HTMLInputElement[] = Array.from(
    document.querySelectorAll(".approval-selector"),
  );

  const selectAllCheckboxes = () => {
    checkboxes.forEach((checkbox) => {
      checkbox.checked = true;
      setLinesForApproval((prev) => [...prev, checkbox.id]);
    });
    setAreAllLinesSelectedForApproval(true);
  };

  const deselectAllCheckboxes = () => {
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    setLinesForApproval([]);
    setAreAllLinesSelectedForApproval(false);
  };

  const handleAllOrderLinesSelectionChange = () => {
    if (areAllLinesSelectedForApproval) {
      deselectAllCheckboxes();
    }

    if (!areAllLinesSelectedForApproval) {
      selectAllCheckboxes();
    }
  };

  const handleSingleOrderLineSelectionChange = (
    workOrderLineId: string,
    add: boolean,
  ) => {
    add
      ? setLinesForApproval((prev) => [...prev, workOrderLineId])
      : setLinesForApproval((prev) =>
          prev.filter((id) => id !== workOrderLineId),
        );

    checkboxes.every((checkbox) => checkbox.checked)
      ? setAreAllLinesSelectedForApproval(true)
      : setAreAllLinesSelectedForApproval(false);
  };

  const handleReopenWorkOrderLine = (orderLineId: string) => {
    reopenWorkorderLine({
      variables: {
        workOrderNumber: workOrderId,
        workOrderLineNumber: orderLineId,
        issuedBy: userData.mail,
      },
    });
  };

  const handleApprovalAttempt = async () => {
    setAreAllLinesSelectedForApproval(false);

    if (canWorkOrderBeClosed()) {
      console.debug("approving", workOrderId, "with orderlines:", {
        orderLines,
      });
      const { data } = await approveInvoice({
        variables: {
          workOrderNumber: workOrderId,
          issuedBy: userData.mail,
        },
      });

      if (!data?.closeWorkOrder?.length) {
        console.error(data);
        captureMessage(
          `error approving invoice of wo: ${workOrderId} | ${data?.closeWorkOrder}`,
        );
      }
      goToStep(2);
    }

    if (linesForApproval.length > 0) {
      linesForApproval.forEach(async (orderLineId) => {
        await approveSingleWorkOrderLine(orderLineId);
      });
      setAwaitingLineApproval(true);
      deselectAllCheckboxes();
    }
  };

  const canWorkOrderBeClosed = () => {
    if (checkIfAllLinesAreApproved()) {
      return true;
    }
    const allLinesCanBeApproved = orderLines.every(
      (line) =>
        line.timestampOperations.length === 0 ||
        parseInt(line.status) > FINISHED_ORDER_LINE_THRESHOLD,
    );

    return allLinesCanBeApproved;
  };

  const approveSingleWorkOrderLine = async (orderLineId: string) => {
    const currentLine = orderLines.find((line) => line.id === orderLineId);
    currentLine.status = FINISHED_ORDER_LINE_THRESHOLD.toString();
    currentLine.derivedStatus = "FINISHED";

    const { data } = await approveWorkorderLine({
      variables: {
        workOrderNumber: workOrderId,
        workOrderLineNumber: orderLineId,
        issuedBy: userData.mail,
      },
    });
  };

  const handleRemoveTimestamp = (id: string) => {
    const newArr = orderLines.map((opLine) => {
      if (opLine?.timestampOperations?.some((el) => id === el.id)) {
        // Create a new array of timestamps excluding the one to be removed
        const newTimestamps = opLine.timestampOperations.filter(
          (el) => el.id !== id,
        );
        // Return a new opLine object with the updated timestamps array
        return { ...opLine, timestampOperations: newTimestamps };
      }
      // Return the opLine unmodified if the condition is not met
      return opLine;
    });
    setOrderLines(newArr);
  };

  const { loading } = useQuery(GET_ALL_COSTING_TYPES, {
    onCompleted: (res) => {
      const parsed = res?.costingTypes?.map((item) => {
        return { value: item?.id, label: item?.description };
      });
      setAllCostingTypes(!isEmpty(parsed) ? parsed : []);
    },
  });

  useQuery(GET_ALL_LABOR_CHARGE_CODES, {
    onCompleted: (res) => {
      const parsed = res?.laborChargeCodes?.map((item) => {
        return { value: item?.id, label: item?.description };
      });
      setAllLaborCodes(!isEmpty(parsed) ? parsed : []);
    },
  });

  const sortedOrderLines = useMemo(() => {
    // Check if orderLines is iterable
    if (!Array.isArray(orderLines)) {
      // Return an empty array or handle the case as appropriate
      return [];
    }
    // Sort the operation lines using the custom comparator
    // Create a new array from orderLines because sometimes could complain about "read-only" array
    return [...orderLines].sort(customOrderLinesSort);
  }, [orderLines]);

  if (loading) {
    return (
      <span style={{ position: "relative", padding: "200px" }}>
        <CircularLoading />
      </span>
    );
  }
  return (
    <div className={styles.tableStep1}>
      {waitingReview && (
        <div className={styles.overlayLoading}>
          <img src={clockBig} alt="loading" />
          <span>Syncing invoice data with M3</span>
          <div />
        </div>
      )}
      {errorMessage && (
        <div className={`${styles.warningArea}`}>
          <img
            src={warningIcon}
            alt="Warning"
            style={{
              margin: "4px 15px -5px 3px",
              color: "black",
              borderColor: "#00ADBB",
            }}
          />
          {errorMessage}
        </div>
      )}
      {sortedOrderLines
        ?.filter((orderLine) => {
          return orderLine?.timestampOperations?.[0];
        })
        .map((orderLine) => (
          <WorkOrderLine
            key={workOrderId + orderLine.id}
            handleRemoveTimestamp={handleRemoveTimestamp}
            allLaborCodes={allLaborCodes}
            allCostingTypes={allCostingTypes}
            orderLineData={orderLine}
            handleSelectedChange={handleSingleOrderLineSelectionChange}
            awaitingApproval={awaitingLineApproval}
            reopenWorkOrderLine={handleReopenWorkOrderLine}
          />
        ))}
      <br />
      <div
        className={`${styles.wizardButtonSection}`}
        style={{
          justifyContent: "flex-end",
        }}
      >
        <div>
          <button
            className={`${textStyles.primaryText} ${styles.invoiceWizardButton} `}
            onClick={handleAllOrderLinesSelectionChange}
            disabled={checkboxes.length === 0 || awaitingLineApproval}
          >
            {!areAllLinesSelectedForApproval ? "Select" : "Deselect"} All
          </button>
          <button
            className={`${textStyles.primaryText} ${styles.invoiceWizardButton} `}
            type="button"
            onClick={handleApprovalAttempt}
            title="Approve and go to next step: review invoice"
            disabled={
              awaitingLineApproval ||
              (linesForApproval.length === 0 && !canWorkOrderBeClosed())
            }
          >
            {canWorkOrderBeClosed()
              ? "Proceed to Invoicing"
              : "Approve selection"}
          </button>
          <div
            style={{
              verticalAlign: "text-top",
              lineHeight: "1.1em",
              width: "450px",
            }}
          >
            <img
              src={warningIcon}
              alt="Warning"
              style={{
                margin: "4px 10px 0px 3px",
                color: "black",
                borderColor: "#00ADBB",
              }}
            />
            <span
              className={textStyles.secondaryText}
              style={{
                paddingBottom: 40,
              }}
            >
              You will not be able to return to edit REVIEW ORDER step until the
              whole invoicing process is approved and completed
            </span>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTableStep1;
