import { useMsal } from "@azure/msal-react";
import isEmpty from "lodash/isEmpty";
import React, { useMemo } from "react";

import GroupButtons from "@/components/common/formElements/groupButtons";
import CustomMultiSelect from "@/components/common/formElements/multiselect/customMultiSelect";
import PopUp from "@/components/common/formElements/popUp";
import { OrderStatusColors } from "@/helpers/OrderStatusColors";
import useGetWorkOrderTypes from "@/hooks/workOrderTypes/useGetWorkOrderTypes";
import arrowIcon from "@/style/img/arrow.svg";
import bell from "@/style/img/bell.svg";
import clockIcon from "@/style/img/clock.svg";
import avatar from "@/style/img/default_avatar.svg";
import linesIcon from "@/style/img/lines.svg";
import notificationOff from "@/style/img/notificationOff.svg";
import {
  GhostActivity,
  OrderStatusColor,
  SelectSingleProps,
  SelectWithcolor,
  WorkOrder,
  WorkOrdertype,
} from "@/types";
import { useOrderStore } from "@/zustand/useOrderStore";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

const uniqueByKey = (array, key) => {
  const seen = new Set();
  return array.filter((item) => {
    const keyValue = item[key];
    if (seen.has(keyValue)) {
      return false;
    }
    seen.add(keyValue);
    return true;
  });
};
const OrderFilters: React.FC = () => {
  const inDetailedView = useSettingsStore((state) => state.inDetailedView);
  const setInDetailedView = useSettingsStore(
    (state) => state.setInDetailedView,
  );
  const allCollapsed = useSettingsStore((state) => state.allCollapsed);
  const setAllCollapsed = useSettingsStore((state) => state.setAllCollapsed);

  const isSnackbarOn = useSettingsStore((state) => state.isSnackbarOn);
  const setIsSnackbarOn = useSettingsStore((state) => state.setIsSnackbarOn);

  const allOrders = useOrderStore((state) => state.allOrders);

  const selectedStatus = useSettingsStore((state) => state.selectedStatus);
  const selectedOrderType = useSettingsStore(
    (state) => state.selectedOrderType,
  );
  const setSelectedStatus = useSettingsStore(
    (state) => state.setSelectedStatus,
  );
  const setOrderTypeFilter = useSettingsStore(
    (state) => state.setOrderTypeFilter,
  );

  const workOrderTypesData = useGetWorkOrderTypes();
  const workOrderTypeOptions = workOrderTypesData.map((w: WorkOrdertype) => {
    return {
      value: w.id,
      label: `${w.name
        .toUpperCase()
        .replace(/\s*\([^)]*\)/g, "")
        .trim()}`,
    };
  });

  const userData = useUserData();
  const { displayName } = userData;

  const { instance } = useMsal();

  const mapOrderStatusToMultiSelect = useMemo(() => {
    const result: { value: string; label: string; color: string }[] = [];

    OrderStatusColors.asArray().forEach(
      (el: OrderStatusColor, index: number) => {
        result.push({
          value: index.toString(),
          label: el.name,
          color: el.color,
        });
      },
    );
    return result;
  }, []);

  const preselectedTypes = useMemo(() => {
    let res = [];

    if (!isEmpty(allOrders)) {
      const availableOrderIds = Object.values(allOrders)
        .filter((order) => !!order?.normalizedQuay)
        .filter(
          (order) =>
            order.normalizedOrderType === "ghost" ||
            order.normalizedOrderType === "wo",
        )
        .map((wo: WorkOrder | GhostActivity) => {
          if (wo.normalizedQuay?.includes("kai-")) {
            return { label: "VESSELS", value: "104" };
          }
          return {
            label: wo.normalizedQuay, // it's not the right label, but anyway, it works
            value: wo.normalizedQuay,
          };
        });
      res = uniqueByKey(
        [...[{ label: "VESSELS", value: "104" }], ...availableOrderIds],
        "value",
      );
    }
    return res;
  }, [allOrders]);

  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/login",
      mainWindowRedirectUri: "/login",
    });
  };

  return (
    <div className={styles.orderFilters}>
      <div
        className={`${styles.allCollapsed} ${
          allCollapsed ? styles.allCollapsedEnabled : ""
        }`}
      >
        <GroupButtons
          firstTitle={arrowIcon}
          iconsAllowed
          activeButtonIndex={allCollapsed ? 2 : 1} // 1 is Enabled
          handleActiveButton={() => {
            setAllCollapsed(!allCollapsed);
          }}
          leftButtonText="Expand/collapse details for all Rental lines"
        />
      </div>
      <GroupButtons
        firstTitle={linesIcon}
        secondTitle={clockIcon}
        iconsAllowed
        activeButtonIndex={inDetailedView ? 1 : 2} // 1 is Enabled
        handleActiveButton={setInDetailedView}
        leftButtonText="Detailed view"
        rightButtonText="Timed view"
      />
      <CustomMultiSelect
        onChange={(values: SelectWithcolor[] | SelectSingleProps[]) => {
          setSelectedStatus(values);
        }}
        placeholder="ORDER STATUS"
        options={mapOrderStatusToMultiSelect}
        allButtonName="Select all"
        noneButtonName="Deselect all"
        preselected={[]}
        activeOptions={selectedStatus}
        storageKey="order-status-select"
      />
      <CustomMultiSelect
        onChange={(value: SelectWithcolor[] | SelectSingleProps[]) => {
          setOrderTypeFilter(value);
        }}
        placeholder="ORDER TYPE"
        options={workOrderTypeOptions}
        allButtonName="Select all"
        noneButtonName="Deselect all"
        preselected={preselectedTypes}
        activeOptions={selectedOrderType}
        storageKey="order-type-select"
        withPreselectionToggle
      />
      <div className={`${styles.orderFilters} ${styles.userInfo}`}>
        <div
          className="flex-center"
          onClick={setIsSnackbarOn}
          onKeyPress={setIsSnackbarOn}
          role="button"
          tabIndex={0}
        >
          {isSnackbarOn ? (
            <img src={bell} alt="notifications on" />
          ) : (
            <img src={notificationOff} alt="notifications off" />
          )}
        </div>
        <div className="flex-center">
          <img src={avatar} alt="avatar" />
        </div>
        <PopUp
          trigger={
            <div className={textStyles.primaryText}>{displayName || ""}</div>
          }
          content={
            <div
              tabIndex={0}
              role="button"
              onKeyDown={() => null}
              onClick={handleLogout}
              className="popUp-wrapper"
            >
              Log Out
            </div>
          }
        />
      </div>
    </div>
  );
};

export default OrderFilters;
