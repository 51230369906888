/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from "@mui/material";
import omit from "lodash/omit";
import moment from "moment-timezone";
import React, {
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import DialogModal from "@/components/common/dialogModal";
import { LOADING_STYLE } from "@/helpers/constants";
import {
  OrderStatusColors,
  OrderStatusEnum,
} from "@/helpers/OrderStatusColors";
import { useDraggableItem } from "@/hooks/ui/timeline/useDraggableItem";
import { useNeedToMakeItemSecondary } from "@/hooks/ui/timeline/useNeedToMakeItemSecondary";
import { useOrderSize } from "@/hooks/ui/timeline/useOrderSize";
import {
  DraggableItemProps,
  DraggableItemType,
  OnDraggableSideDropFn,
  VisualColorTheme,
  WorkOrder as WorkOrderTypes,
} from "@/types";
import { useInProgressStore } from "@/zustand/useInProgressStore";
import {
  DEFAULT_ALLOCATION_HEIGHT,
  setSidePanelOrder,
  useOrderStore,
} from "@/zustand/useOrderStore";
import { useSettingsStore } from "@/zustand/useSettingsStore";
import { SuperLinkTeam } from "@/zustand/zustand-types";

import OrderCardDraggableSides from "../draggableSide/orderCardDraggableSides";
import { isInsideModal } from "../helpers/isInsideModal";
import AssignTeamModal from "./modalsContent/assignTeamModal";
import DeleteWorkOrderModal from "./modalsContent/deleteWorkOrderModal";
import WorkOrderActions from "./workOrderActionsPopUp";

import styles from "../index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type DragWorkOrderProps = {
  id: string;
  workOrder: WorkOrderTypes;
  elementWidth: number;
  position: number;
  selectedDate: Date;
  visibleDays: number;
  timeLine: string[];
  onPullEnd: OnDraggableSideDropFn;
};

const draggableItemType = DraggableItemType.WorkOrder;

const WorkOrderAllocation = forwardRef<HTMLInputElement, DragWorkOrderProps>(
  (
    {
      workOrder,
      elementWidth,
      position,
      selectedDate,
      visibleDays,
      timeLine,
      onPullEnd,
    },
    ref,
  ) => {
    const sidePanelOrder = useOrderStore((state) => state.sidePanelOrder);
    const woChangeInProgress = useInProgressStore(
      (state) => state.woChangeInProgress,
    );
    const woLineChangeInProgress = useInProgressStore(
      (state) => state.woLineChangeInProgress,
    );
    const inDetailedView = useSettingsStore((state) => state.inDetailedView);
    const [isModalDeleteVessel, setIsModalDeleteVessel] = useState(false);
    const [startAssignment, setStartAssignment] = useState(false);
    const { visibleStartDate } = useSettingsStore(
      (state) => state.visibleDateRange,
    );
    const superLinking = useSettingsStore((state) => state.superLinking);
    const disableSuperLinkTooltip = useSettingsStore(
      (state) => state.disableSuperLinkTooltip,
    );

    const orderInfo = useRef<any>(null);

    const isLoading1 = woChangeInProgress.some(
      (each) => each.id === workOrder.id,
    );
    const isLoading2 = woLineChangeInProgress.some((inProgress) => {
      return workOrder?.orderLines?.some(
        (eachLine) => eachLine.id === inProgress.id,
      );
    });
    const isLoading = isLoading1 || isLoading2;
    const draggableProps: DraggableItemProps = {
      canDrag: !isLoading,
      quayId: workOrder.normalizedQuay,
      dragScope: [workOrder.normalizedQuay],
    };

    const [{ isDragging }, drag] = useDraggableItem({
      type: draggableItemType,
      timeLine,
      orderId: workOrder.id,
      ...draggableProps,
    });

    const getVisualColorTheme = useCallback(
      (status: string): VisualColorTheme => {
        return isLoading
          ? LOADING_STYLE
          : OrderStatusColors.getStatus(status as OrderStatusEnum);
      },
      [isLoading],
    );

    const needToMakeItemSecondary = useNeedToMakeItemSecondary({
      order: workOrder,
    });

    const { width } = useOrderSize({
      order: workOrder,
      selectedDate,
      elementWidth,
      visibleDays,
    });

    // @TODO: check can the implementation from the vessels card be reused
    const orderStyles = useMemo(() => {
      let finalWidth = Math.max(width, elementWidth * 3); // elementWidth*3, why 3 (3 hours)? Because it's min width of 3 hours we declared in intervalGraphColoring.
      // Calculations in minutes
      const finalStartDate = workOrder.plannedStartDate;
      // Calculate the time difference in minutes
      let startDiff = -visibleStartDate.diff(finalStartDate, "minutes");

      // Detect if there's a DST boundary between `visibleStartDate` and `finalStartDate`
      const visibleIsDST = moment(visibleStartDate).isDST();
      const startIsDST = moment(finalStartDate).isDST();

      // Adjust startDiff by 60 minutes if a DST boundary was crossed
      if (visibleIsDST !== startIsDST) {
        startDiff -= 60; // Subtract 1 hour if crossing into DST
      }

      // Convert minutes to pixels for the `left` position
      const left = (startDiff > 0 ? startDiff : 0) * (elementWidth / 60);

      const timelineContainerWidth = (elementWidth / 2) * 48 * 2; // TODO: don't hardcode
      if (left + finalWidth > timelineContainerWidth) {
        finalWidth = timelineContainerWidth - left; // To prevent DetailedView allocations to overflow the right edge of the timeline
      }

      return {
        borderColor: getVisualColorTheme(workOrder.derivedStatus).color,
        width: finalWidth || 0,
        borderWidth: sidePanelOrder?.id === workOrder.id ? "4px" : "2px",
        boxShadow:
          sidePanelOrder?.id === workOrder.id
            ? "0px 8px 10px rgba(90, 90, 90, 0.14), 0px 3px 14px rgba(90, 90, 90, 0.12), 0px 4px 5px rgba(90, 90, 90, 0.2)"
            : "none",
        height: workOrder?.allocHeight,
        top: workOrder?.visualTop,
        left,
        pointerEvents:
          isDragging || needToMakeItemSecondary
            ? "none"
            : ("inherit" as React.CSSProperties["pointerEvents"]),
        color: isLoading ? "#C4C4C4" : "inherit",
      };
    }, [
      width,
      elementWidth,
      workOrder.plannedStartDate,
      workOrder.derivedStatus,
      workOrder.id,
      workOrder?.allocHeight,
      workOrder?.visualTop,
      visibleStartDate,
      getVisualColorTheme,
      sidePanelOrder?.id,
      isDragging,
      needToMakeItemSecondary,
      isLoading,
    ]);

    const renderEventInfo = (order: WorkOrderTypes) => {
      const textStyle = {
        overflow: `${inDetailedView ? "visible" : "hidden"}`,
        fontWeight: 700,
        color: isLoading ? "#C4C4C4" : "inherit",
      };
      const subtitleStyle = {
        overflow: `${inDetailedView ? "visible" : "hidden"}`,
        color: isLoading ? "#C4C4C4" : "inherit",
        marginTop: "8px",
      };

      const JSX = (
        <div
          key={order.id}
          className={styles.workOrderBlock}
          style={{ display: "block" }}
        >
          <div className={styles.eventInfo}>
            <span style={textStyle} className={textStyles.primaryText}>
              {`${order.description} - ${order.customerDisplayName}`}
            </span>
          </div>

          <div className={styles.eventInfoBlock}>
            <div
              key={order.id}
              className={styles.workOrderBlock}
              style={{ display: "flex" }}
            >
              <div className={styles.eventInfo}>
                <span
                  style={subtitleStyle}
                  className={textStyles.secondaryText}
                >
                  {`#${order.orderNumber.substring(
                    order.orderNumber.length - 5,
                  )}`}

                  {/* <span
                    style={subtitleStyle}
                    className={textStyles.secondaryText}
                  >
                    {workOrder?.orderLines && ` - ${teamInfo}`}
                    {!workOrder?.orderLines && "..."}
                  </span> */}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
      return JSX;
    };
    useMemo(() => {
      if ((superLinking as SuperLinkTeam)?.team) {
        orderStyles.boxShadow = "0px 0px 6px 1px rgba(100, 100, 100, 1)";
      } else if (!sidePanelOrder?.id) {
        orderStyles.boxShadow = "none";
      }
    }, [orderStyles, sidePanelOrder?.id, superLinking]);

    // @TODO: check can the implementation from the vessels card be reused
    const wrapperStyles = useMemo(() => {
      const widthCSS = "fit-content";
      const flexDirection = "column";
      const alignItems = "";
      return {
        width: widthCSS,
        flexDirection,
        alignItems,
        display: "flex",
      };
    }, []);

    const orderContentWrapperStyles = {
      backgroundColor: isDragging
        ? "transparent"
        : getVisualColorTheme(workOrder.derivedStatus).background,
      width: Math.max(width - 3, 0),
    };

    const handleChangeModalDeleteState = () => {
      setIsModalDeleteVessel(!isModalDeleteVessel);
    };

    const closeAssignmentModal = () => {
      setStartAssignment(false);
    };

    const onAllocationClick = (e) => {
      if (isLoading) {
        return;
      }
      // Handle edge case. "onAllocationClick" gets triggered also when clicking away from the 3-dots menu, or click modal buttons
      if (
        e.target?.classList?.contains("popup-overlay") ||
        e.target?.classList?.value?.includes("modalButton") ||
        isInsideModal(
          e.target,
          "MuiDialog-root",
          "MuiModal-root",
          "MuiPopper-root",
        )
      ) {
        return;
      }

      if ((superLinking as SuperLinkTeam)?.team) {
        setStartAssignment(true);
        disableSuperLinkTooltip();
      } else {
        // setTimeout fixes the race condition in the click event handling that
        // It allows the previous active sidepanel to have time detect clickaway and close itself + saving data before opening this one
        setTimeout(() => {
          setSidePanelOrder({ id: workOrder?.id });
        }, 5);
      }
      console.debug({ workOrder });
    };

    return (
      <>
        <div
          className="floatingLabel"
          ref={ref}
          data-allocation-width={orderStyles.width - 30}
          data-allocation-offsetleft={orderStyles.left}
          style={{
            ...orderContentWrapperStyles,
            ...{
              position: "absolute",
              top:
                workOrder?.allocHeight > DEFAULT_ALLOCATION_HEIGHT / 2
                  ? orderStyles.top + 12
                  : orderStyles.top + 8,
              left: orderStyles.left + 20,
              right: orderStyles.left + 30,
              backgroundColor: "transparent",
              zIndex: 2,
              width: Math.max(orderStyles.width - 60, 20),
              overflow: "hidden",
            },
          }}
        >
          <div ref={orderInfo} style={wrapperStyles as React.CSSProperties}>
            {renderEventInfo(workOrder)}
          </div>
        </div>

        <div
          className={styles.tableOrderContainer}
          style={{
            marginLeft: position,
            top: orderStyles.top,
            height: orderStyles.height,
            left: orderStyles.left, // This "left" value will be copied to "floatingLabel" component when scrolling. See item.nextSibling.offsetLeft in timelineFloatingLabels
          }}
          tabIndex={0}
          role="button"
          onClick={onAllocationClick}
          onKeyDown={() => null}
        >
          <DialogModal
            isOpen={isModalDeleteVessel}
            onClose={handleChangeModalDeleteState}
          >
            <DeleteWorkOrderModal
              changeModalState={handleChangeModalDeleteState}
              orderId={workOrder.id}
            />
          </DialogModal>
          <DialogModal isOpen={startAssignment} onClose={closeAssignmentModal}>
            <AssignTeamModal
              isModalAssign={startAssignment}
              changeModalState={closeAssignmentModal}
              workOrders={[workOrder]}
              teamData={(superLinking as SuperLinkTeam)?.team}
            />
          </DialogModal>
          <OrderCardDraggableSides
            {...draggableProps}
            canDrag={!isLoading}
            parentItemType={draggableItemType}
            orderId={workOrder?.id}
            onDrop={onPullEnd}
            height={`${orderStyles.height}px`}
            orderRegularWidth={width}
            orderExpandedWidth={orderStyles.width as number}
            inDetailedView={inDetailedView}
          />
          <div
            className={styles.tableEvent}
            style={{
              ...omit({ ...orderStyles }, ["top", "left", "height"]),
              cursor: isLoading ? "progress" : "",
              opacity: isDragging ? "0.7" : "1",
              boxShadow: isDragging
                ? "inset 0px 0px 10px 0px rgba(0, 0, 0, 0.3)"
                : orderStyles.boxShadow,
              width: Math.max(orderStyles.width, 45),
              borderColor: width <= 0 ? "#DD877E" : orderStyles.borderColor,
            }}
            ref={drag}
          >
            <div style={{ ...orderContentWrapperStyles }}>
              {isLoading && (
                <CircularProgress
                  size={24}
                  color="secondary"
                  style={{
                    zIndex: 999,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
              <WorkOrderActions order={workOrder} />
            </div>
          </div>
        </div>
      </>
    );
  },
);
export default memo(WorkOrderAllocation);
