/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import * as Sentry from "@sentry/react";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import CircularDeterminateLoader from "@/components/common/circularDeterminateLoader";
import SentryFallback from "@/components/common/sentryFallback";
import GhostSVG from "@/components/sidebar/svgElements/ghost";
import { captureError, captureMessage } from "@/helpers/captureError";
import {
  OrderStatusColors,
  OrderStatusEnum,
} from "@/helpers/OrderStatusColors";
import doneMark from "@/style/img/done.svg";
import arrowIcon from "@/style/img/icons/arrow_up.svg";
import {
  Nullable,
  OrderUpdatedEventParams,
  WorkOrder as WorkOrderTypes,
} from "@/types";
import {
  closeSidePanel,
  createMapById,
  mergeOrderIntoCollections,
  mutateGhost,
  mutateVessel,
  mutateWorkOrder,
} from "@/zustand/useOrderStore";
import { useSnackbar } from "@/zustand/useSnackbar";

import { isInsideModal } from "../helpers/isInsideModal";
import AssignResourcesPanel from "./assignResources/assignResources";
import { checkStartBeforeStop } from "./checkStartBeforeStop";
import GhostSidePanel from "./ghostSidePanel";
import VesselSidePanel, { AllocatedVesselData } from "./vesselSidePanel";
import WorkOrderSidePanel from "./workOrderSidePanel";

import styles from "./sidePanel.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = { sidePanelOrder: any };

const pickEditableFields = (order) => {
  return pick(order, "plannedStartDate", "plannedStopDate");
};

const SidePanel: FC<Props> = ({ sidePanelOrder }) => {
  const [activeOverview, setActiveOverview] = useState(true);

  // These three, hold temporary changes until user clicks away from the sidePanel (triggering the mutation request)
  const [updatedWorkOrder, setUpdatedWorkOrder] = useState<any>({});
  const [updatedGhostOrder, setUpdatedGhostOrder] = useState<any>({});
  const [updatedVessel, setUpdatedVessel] = useState<
    Nullable<Partial<AllocatedVesselData>>
  >({});

  const [activeWorkOrders, setActiveWorkOrders] = useState(false);
  const [areValuesOffSync, setOffSync] = useState(0);
  const [isResourcesPanelActive, setIsResourcesPanelActive] = useState(false);
  const [isInvoicePanelActive, setIsInvoicePanelActive] = useState(false);
  const [activeWorkOrderLine, setActiveWorkOrderLine] = useState<
    string | undefined
  >(undefined);
  const setSnack = useSnackbar((state) => state.setSnack);
  const activeWorkOrderLineId = (activeWorkOrderLine ?? "")?.split("|")[1];
  const assignResourceRef: RefObject<HTMLDivElement> = useRef(null);
  const displayingVessel = sidePanelOrder?.normalizedOrderType === "vessel";
  const displayingSingleWorkOrder =
    sidePanelOrder?.__typename == "WorkOrderWithLines" ||
    sidePanelOrder?.__typename == "WorkOrderWithLoadingStatusSummary" ||
    sidePanelOrder?.normalizedOrderType === "wo" ||
    sidePanelOrder?.normalizedOrderType === "subwo";
  const displayingGhost = sidePanelOrder?.normalizedOrderType === "ghost";

  // To track off-sync values (other users are concurrently editing the order values), and show a warning.
  const [originalValues, setOriginalValues] = useState({} as any);

  useEffect(() => {
    if (sidePanelOrder?.id) {
      setOriginalValues(pickEditableFields(sidePanelOrder));
    }
  }, [sidePanelOrder]);

  useEffect(() => {
    // Check if originalValues have changed, and if so, show a warning
    if (!isEmpty(originalValues)) {
      if (
        originalValues?.plannedStartDate !== sidePanelOrder?.plannedStartDate ||
        originalValues?.plannedStopDate !== sidePanelOrder?.plannedStopDate
      ) {
        setUpdatedWorkOrder({});
        setUpdatedGhostOrder({});
        setUpdatedVessel({});
        setOriginalValues(pickEditableFields(sidePanelOrder));
        setOffSync(1); // Stage 1: Show the values are going to sync.
        setTimeout(() => {
          setOffSync(2); // Stage: Sync now and show sync done message
        }, 4000); // TODO: make it variable, pass it to the spinner
        setTimeout(() => {
          setOffSync(0); // Reset this state to 0 again
        }, 6000);
      }
    }
  }, [
    originalValues,
    sidePanelOrder,
    sidePanelOrder?.plannedStartDate,
    sidePanelOrder?.plannedStopDate,
    sidePanelOrder?.poNumber,
    sidePanelOrder?.orderedBy,
  ]);

  const getCurrentStatus = useMemo(() => {
    if (displayingVessel) {
      return OrderStatusColors.getStatus(
        sidePanelOrder?.status as OrderStatusEnum,
      );
    }
    return OrderStatusColors.getUnknownStatus();
  }, [displayingVessel, sidePanelOrder]);

  // to avoid rerender loop.
  const setUpdateWorkOrderMemoized = useCallback(
    (newUpdate) => {
      setUpdatedWorkOrder((prevState) => {
        if (isEmpty(newUpdate)) {
          // reset
          return {};
        }
        if (!newUpdate?.id) {
          captureMessage(
            "no id in the work order that user is trying to update",
          );
          return prevState;
        }
        const clonedState = JSON.parse(JSON.stringify(prevState));
        if (clonedState?.[newUpdate.id]) {
          clonedState[newUpdate.id] = {
            ...clonedState[newUpdate.id],
            ...newUpdate,
          };
        } else {
          clonedState[newUpdate.id] = newUpdate;
        }
        return clonedState;
      });
    },
    [setUpdatedWorkOrder],
  );

  const setUpdatedGhostOrderMemoized = useCallback(
    (newUpdate) => {
      setUpdatedGhostOrder({ ...updatedGhostOrder, ...newUpdate });
    },
    [updatedGhostOrder],
  );

  const setUpdatedVesselMemoized = useCallback(
    (newUpdate: Partial<AllocatedVesselData>) => {
      setUpdatedVessel({ ...updatedVessel, ...newUpdate });
    },
    [updatedVessel],
  );

  const onCloseOrderDetails = useCallback(() => {
    if (areValuesOffSync > 0) {
      setSnack(
        true,
        "Sorry, another user has changed the details of this order. Try again",
        true,
        "error",
      );
      return;
    }

    try {
      // GHOST --------------------------
      if (!isEmpty(updatedGhostOrder)) {
        // TODO: Optimize even more: if users "touches" a field, but value remains the same, then we shouldn't need to trigger the mutation

        const plannedStartDate =
          updatedGhostOrder?.plannedStartDate ||
          sidePanelOrder?.plannedStartDate;
        const plannedStopDate =
          updatedGhostOrder?.plannedStopDate || sidePanelOrder?.plannedStopDate;
        const name = updatedGhostOrder?.name || sidePanelOrder?.name;
        const customerName =
          updatedGhostOrder?.customerName || sidePanelOrder?.customerName;
        const reserve = updatedGhostOrder?.reserve || sidePanelOrder?.reserve;
        const finalUpdate: any = {
          id: sidePanelOrder?.id,
          name,
          customerName,
          plannedStartDate,
          plannedStopDate,
          reserve,
        };

        if (checkStartBeforeStop(finalUpdate, setSnack)) {
          mutateGhost(finalUpdate);
        }
      }

      // SINGLE WORK ORDER --------------------------
      if (displayingSingleWorkOrder) {
        const singleWO = updatedWorkOrder?.[sidePanelOrder?.id];
        if (
          !isEmpty(singleWO) &&
          (singleWO?.plannedStartDate !== undefined ||
            singleWO?.plannedStopDate !== undefined)
        ) {
          // Fill the gaps with previousWO data:
          const plannedStartDate =
            singleWO?.plannedStartDate || sidePanelOrder?.plannedStartDate;
          const plannedStopDate =
            singleWO?.plannedStopDate || sidePanelOrder?.plannedStopDate;
          const finalUpdate: any = {
            id: sidePanelOrder?.id,
            plannedStartDate,
            plannedStopDate,
          };
          if (checkStartBeforeStop(finalUpdate, setSnack)) {
            mutateWorkOrder(
              finalUpdate,
              sidePanelOrder?.normalizedOrderType === "subwo",
            );
          }
        }
      }

      // MULTIPLE WORK ORDER(s) (aka, attached to a Vessel) --------------------------
      const vesselWorkOrdersPayloads = {};
      if (displayingVessel) {
        if (!isEmpty(updatedWorkOrder)) {
          const allWOsById = createMapById(sidePanelOrder?.workOrders);
          Object.values(updatedWorkOrder).forEach(
            (eachEditedWO: Nullable<Partial<OrderUpdatedEventParams>>) => {
              const fullWO = allWOsById[eachEditedWO?.id];
              if (isEmpty(fullWO)) {
                captureMessage(
                  `Missing data for WO id ${updatedWorkOrder?.id}. Order ${sidePanelOrder?.id}`,
                );
                return;
              }

              if (
                eachEditedWO?.plannedStartDate !== undefined ||
                eachEditedWO?.plannedStopDate !== undefined
              ) {
                // Fill the gaps with previousWO data:
                const plannedStartDate =
                  eachEditedWO?.plannedStartDate || fullWO?.plannedStartDate;
                const plannedStopDate =
                  eachEditedWO?.plannedStopDate || fullWO?.plannedStopDate;
                const finalUpdate: any = {
                  id: fullWO?.id,
                  plannedStartDate,
                  plannedStopDate,
                };

                if (checkStartBeforeStop(finalUpdate, setSnack)) {
                  mutateWorkOrder(finalUpdate, true);
                  vesselWorkOrdersPayloads[finalUpdate.id] = finalUpdate;
                }
              }
            },
          );
        }
      }

      // VESSEL's WORK ORDERS   --------------------------
      if (displayingVessel && !isEmpty(vesselWorkOrdersPayloads)) {
        mergeOrderIntoCollections("vessel", {
          id: sidePanelOrder?.id,
          workOrders: vesselWorkOrdersPayloads,
        });
      }

      // VESSEL --------------------------
      if (displayingVessel) {
        if (
          updatedVessel?.plannedStartDate !== undefined ||
          updatedVessel?.plannedStopDate !== undefined
        ) {
          // TODO: Optimize even more: if users "touches" a field, but value remains the same, then we shouldn't need to trigger the mutation
          const plannedStartDate =
            updatedVessel?.plannedStartDate || sidePanelOrder?.plannedStartDate;
          const plannedStopDate =
            updatedVessel?.plannedStopDate || sidePanelOrder?.plannedStopDate;

          const finalUpdate: any = {
            id: sidePanelOrder?.id,
            plannedStartDate,
            plannedStopDate,
            quay: sidePanelOrder?.quay as string,
            // This is a bit hacky maybe? it can be missleading.
            // Special case, we don't take the "previous" sidePanelOrder value, because we won't need it.
            // "workOrders" are not sent to the server. Only to do the optimistic update in the (mutateVessel) part,
            // where it will be deep-merged with the previous value.
            workOrders: vesselWorkOrdersPayloads,
          };

          if (checkStartBeforeStop(finalUpdate, setSnack)) {
            mutateVessel(finalUpdate);
          }
        }
      }
    } catch (e) {
      captureError(e);
    }
    setUpdatedVesselMemoized({});
    setUpdateWorkOrderMemoized({});
    setUpdatedGhostOrderMemoized({});
    // This will (visually) close the sidePanel
    closeSidePanel();
  }, [
    areValuesOffSync,
    setUpdatedVesselMemoized,
    setUpdateWorkOrderMemoized,
    setUpdatedGhostOrderMemoized,
    setSnack,
    updatedGhostOrder,
    displayingSingleWorkOrder,
    displayingVessel,
    sidePanelOrder?.plannedStartDate,
    sidePanelOrder?.plannedStopDate,
    sidePanelOrder?.name,
    sidePanelOrder?.customerName,
    sidePanelOrder?.reserve,
    sidePanelOrder?.id,
    sidePanelOrder?.normalizedOrderType,
    sidePanelOrder?.workOrders,
    sidePanelOrder?.quay,
    updatedWorkOrder,
    updatedVessel?.plannedStartDate,
    updatedVessel?.plannedStopDate,
  ]);

  const setResourcesPanelActive = (workOrderLineId?: string) => {
    if (workOrderLineId) {
      setActiveWorkOrderLine(workOrderLineId);
      setIsResourcesPanelActive(true);
      setIsInvoicePanelActive(false);
    } else {
      setActiveWorkOrderLine(undefined);
      setIsResourcesPanelActive(false);
      setIsInvoicePanelActive(false);
    }
  };
  const handleClickAway = (e) => {
    // Don't close the sidePanel when downloading an attachment, or when manipulating allocation actions in modals
    if (
      !e?.target?.download &&
      !isInsideModal(
        e?.target,
        "MuiDialog-root",
        "MuiModal-root",
        "MuiPopper-root",
      )
    ) {
      onCloseOrderDetails();
      setIsResourcesPanelActive(false);
      setIsInvoicePanelActive(false);
    }
  };

  const renderVesselDetails = () => {
    return (
      <div className={styles.orderDetailsWrapper}>
        <h2 className={textStyles.primaryText}>ORDER DETAILS</h2>

        <div className={styles.infoBlock}>
          <div className={styles.fieldNames}>
            <span className={textStyles.secondaryText}>Customer:</span>
            <span className={textStyles.secondaryText}>
              {sidePanelOrder?.vessel ? "Vessel:" : "Work Order:"}
            </span>
            <span className={textStyles.secondaryText}>Status:</span>
          </div>
          <div className={styles.fieldInfo}>
            <span className={textStyles.primaryText}>
              {sidePanelOrder?.customerName || "N/A"}
            </span>
            <span className={textStyles.primaryText}>
              {sidePanelOrder?.vesselName || "N/A"}
            </span>
            <div className={`${styles.status}`}>
              <aside
                className="status-indicator"
                style={{ background: `${getCurrentStatus?.color}` }}
              />
              <span className={`${textStyles.primaryText} status-label`}>
                {getCurrentStatus?.name}
              </span>
            </div>
          </div>
        </div>
        <section
          tabIndex={0}
          role="button"
          onKeyDown={() => null}
          onClick={() => setActiveOverview(!activeOverview)}
          className={styles.expandedSection}
        >
          <img
            style={!activeOverview ? { transform: "rotate(180deg)" } : {}}
            src={arrowIcon}
            alt="arrow"
          />
          <span className={textStyles.primaryText}>Vessel Overview</span>
        </section>

        {activeOverview && displayingVessel && (
          <VesselSidePanel
            // If areValuesOffSync is in stage 1, still show the old values (artificial delay for better UX)
            key={areValuesOffSync === 1 ? 0 : areValuesOffSync} // forces re-mount, discarding local edited fields values
            data={sidePanelOrder}
            setUpdatedVessel={setUpdatedVesselMemoized}
          />
        )}

        {!isEmpty(sidePanelOrder?.workOrders) &&
          Array.isArray(sidePanelOrder?.workOrders) && (
            <>
              <section
                tabIndex={0}
                role="button"
                onKeyDown={() => null}
                onClick={() => setActiveWorkOrders(!activeWorkOrders)}
                className={styles.expandedSection}
              >
                <img
                  style={
                    !activeWorkOrders ? { transform: "rotate(180deg)" } : {}
                  }
                  src={arrowIcon}
                  alt="arrow"
                />
                <span className={textStyles.primaryText}>Work orders</span>
              </section>
              {activeWorkOrders &&
                sidePanelOrder?.workOrders.map((wo: WorkOrderTypes, index) => {
                  const isFirst = index === 0;
                  return (
                    <WorkOrderSidePanel
                      showDivider={!isFirst}
                      // If areValuesOffSync is in stage 1, still show the old values (artificial delay for better UX)
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${index} ${
                        areValuesOffSync === 1 ? 0 : areValuesOffSync
                      }`} // forces re-mount, discarding local edited fields values
                      wo={wo}
                      setResourcesPanelActive={setResourcesPanelActive}
                      activeWorkOrderLine={activeWorkOrderLineId}
                      setUpdatedWorkOrder={setUpdateWorkOrderMemoized}
                      isInvoicePanelActive={isInvoicePanelActive}
                      toggleInvoicePanel={() => {
                        setIsInvoicePanelActive(!isInvoicePanelActive);
                        if (!isInvoicePanelActive) {
                          setIsResourcesPanelActive(false);
                        }
                      }}
                    />
                  );
                })}
            </>
          )}
      </div>
    );
  };

  return sidePanelOrder ? (
    <div
      className={`${styles.sidePanelWrapper} ${
        isResourcesPanelActive ? styles.slideMore : ""
      } ${isInvoicePanelActive ? styles.slideEvenMore : ""}`}
    >
      <Sentry.ErrorBoundary fallback={SentryFallback}>
        <ClickAwayListener onClickAway={handleClickAway}>
          {/* there needs to be a div under ClickAwayListener */}
          <div>
            {areValuesOffSync > 0 ? (
              <div className={`${styles.concurrentBanner} `}>
                {areValuesOffSync === 1 ? (
                  <>
                    <CircularDeterminateLoader />
                    Another user is changing the order.
                  </>
                ) : (
                  <>
                    <img src={doneMark} alt="updated" />
                    <span>You have the latest updates.</span>
                  </>
                )}
              </div>
            ) : null}
            {displayingVessel && renderVesselDetails()}
            {displayingGhost && (
              <div className={styles.orderDetailsWrapper}>
                <div className={styles.ghostDetailsHeader}>
                  <h2 className={textStyles.primaryText}>ORDER DETAILS</h2>
                  <div>
                    <span>Ghost Activity</span>
                    <GhostSVG />
                  </div>
                </div>
                <GhostSidePanel
                  // If areValuesOffSync is in stage 1, still show the old values (artificial delay for better UX)
                  key={areValuesOffSync === 1 ? 0 : areValuesOffSync} // forces re-mount, discarding local edited fields values
                  data={sidePanelOrder}
                  setUpdatedGhost={setUpdatedGhostOrderMemoized}
                />
              </div>
            )}
            {displayingSingleWorkOrder && (
              <div className={styles.orderDetailsWrapper}>
                <h2 className={textStyles.primaryText}>Work order</h2>
                <WorkOrderSidePanel
                  // If areValuesOffSync is in stage 1, still show the old values (artificial delay for better UX)
                  key={areValuesOffSync === 1 ? 0 : areValuesOffSync} // forces re-mount, discarding local edited fields values
                  wo={sidePanelOrder}
                  setResourcesPanelActive={setResourcesPanelActive}
                  activeWorkOrderLine={activeWorkOrderLineId}
                  setUpdatedWorkOrder={setUpdateWorkOrderMemoized}
                  isInvoicePanelActive={isInvoicePanelActive}
                  toggleInvoicePanel={() => {
                    setIsInvoicePanelActive(!isInvoicePanelActive);
                    if (!isInvoicePanelActive) {
                      setIsResourcesPanelActive(false);
                    }
                  }}
                  onClickAway={handleClickAway}
                />
              </div>
            )}
            {activeWorkOrderLine &&
              (displayingVessel || displayingSingleWorkOrder) && (
                <AssignResourcesPanel
                  entry={sidePanelOrder}
                  setResourcesPanelActive={setResourcesPanelActive}
                  activeWorkOrderLineId={activeWorkOrderLineId}
                  ref={assignResourceRef}
                />
              )}
          </div>
        </ClickAwayListener>
      </Sentry.ErrorBoundary>
    </div>
  ) : null;
};

export default SidePanel;
