import moment from "moment-timezone";

import { MINIMUM_MINUTES } from "@/zustand/intervalGraphColoring";

const MIN_IN_HOUR = 60;

export const calculateOrderSize = (
  startDate: string,
  stopDate: string,
  elementWidth: number,
  minimum?: boolean,
): number => {
  const startMoment = moment(startDate);
  const stopMoment = moment(stopDate);

  // Calculate the duration in minutes
  let durationInMinutes = stopMoment.diff(startMoment, "minutes");

  // Check if there's a DST boundary between start and stop dates
  const isStartDST = startMoment.isDST();
  const isEndDST = stopMoment.isDST();

  // Adjust for DST only if there's a transition between DST and non-DST
  if (isStartDST !== isEndDST) {
    const dstAdjustment = isStartDST ? -60 : 60; // Adjust by +1 or -1 hour (60 minutes) depending on DST direction
    durationInMinutes += dstAdjustment;
  }

  // Apply minimum limit if necessary
  let finalMinutes = durationInMinutes;
  if (minimum && durationInMinutes < MINIMUM_MINUTES) {
    finalMinutes = MINIMUM_MINUTES;
  }

  // Calculate the width in pixels based on minutes
  const pxPerMinute = Math.floor(elementWidth * 10) / MIN_IN_HOUR / 10;
  const total = (finalMinutes * Math.round(pxPerMinute * 100)) / 100;

  return total;
};
