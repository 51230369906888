import { gql, useQuery } from "@apollo/client";
import React, { FC, memo, useEffect, useState } from "react";

import arrow from "@/style/img/arrow.svg";
import { WorkOrder } from "@/types";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import FilteredList from "./filteredList";
import SearchFilters from "./searchFilters";

import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

const GET_WORKORDERS_BY_SEARCH_STRING = gql`
  query getWorkOrdersBySearchString(
    $facilityId: String!
    $searchString: String!
  ) {
    workOrdersBySearchString(
      facilityId: $facilityId
      searchString: $searchString
    ) {
      id
      name
      customerName
      customerDisplayName
      description
      name
      orderDate
      orderedBy
      orderNumber
      plannedStartDate
      plannedStopDate
      startDate
      status
      derivedStatus
      stopDate
      poNumber
      mCO {
        referenceOrderLine
        referenceOrderNumber
      }
      workOrderType {
        id
        name
        __typename
      }
      __typename
    }
  }
`;
const Search: FC = () => {
  const closeSidebarTab = useSettingsStore((state) => state.closeSidebarTab);
  const facilityId = useSettingsStore((state) => state.selectedFacility);

  const [searchValue, setSearchValue] = useState("");

  const { data: searchData } = useQuery(GET_WORKORDERS_BY_SEARCH_STRING, {
    variables: {
      facilityId: facilityId,
      searchString: searchValue,
    },
    skip: !searchValue,
  });

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };
  const workOrders: WorkOrder[] = searchData?.workOrdersBySearchString ?? [];
  return (
    <div className={styles.searchWrapper}>
      <div className={styles.searchHeader}>
        <nav>
          <button type="button" onClick={() => closeSidebarTab()}>
            <img src={arrow} alt="arrow" />
            {`SEARCH (${workOrders.length})`}
          </button>
        </nav>
        <SearchFilters handleSearch={handleSearch} />
        {!searchValue && (
          <p className={textStyles.secondaryText}>
            Search by the full order number, or the last few digits.
          </p>
        )}
        {searchValue ? (
          <p className={textStyles.secondaryText}>
            Searching for work orders ending with <b>{searchValue}</b>
          </p>
        ) : (
          ""
        )}
      </div>
      <div style={{ overflowY: "auto" }}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {workOrders ? (
          <FilteredList orders={workOrders} />
        ) : searchValue ? (
          <p className={textStyles.secondaryText}>No results</p>
        ) : null}
      </div>
    </div>
  );
};
export default memo(Search);
