import { gql, useMutation } from "@apollo/client";
import moment from "moment-timezone";
import React, { FC, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";

import InputText from "@/components/common/formElements/inputText";
import { useUserData } from "@/zustand/useSettingsStore";

import styles from "./index.module.scss";

export type InvoiceLine = {
  id: string;
  status: string;
  itemName: string;
  costingTypeDescription: string;
  transactionDate: string;
  quantity: number;
  quotation: string;
  transactionNumber: string;
  discountPercentage: string;
  maintenanceCustomerOrderNumber: string;
  maintenanceCustomerOrderLineNumber: string;
  workOrderNumber: string;
  salesPrice: number;
  totalSalesPrice: number;
  unitPrice: number;
  unit: string;
  total: number;
};

const UPDATE_DISCOUNT = gql`
  mutation updateInvoiceWorkOrderLineDiscountPercentage(
    $discountPercentage: Float!
    $quotation: Int!
    $transactionNumber: Int!
    $maintenanceCustomerOrderNumber: String!
    $maintenanceCustomerOrderLineNumber: String!
    $issuedBy: String!
  ) {
    updateInvoiceWorkOrderLineDiscountPercentage(
      discountPercentage: $discountPercentage
      quotation: $quotation
      transactionNumber: $transactionNumber
      maintenanceCustomerOrderNumber: $maintenanceCustomerOrderNumber
      maintenanceCustomerOrderLineNumber: $maintenanceCustomerOrderLineNumber
      issuedBy: $issuedBy
    )
  }
`;

const UPDATE_QUANTITY = gql`
  mutation updateInvoiceWorkOrderLineQuantity(
    $quantity: Float!
    $quotation: Int!
    $transactionNumber: Int!
    $maintenanceCustomerOrderNumber: String!
    $maintenanceCustomerOrderLineNumber: String!
    $issuedBy: String!
  ) {
    updateInvoiceWorkOrderLineQuantity(
      quantity: $quantity
      quotation: $quotation
      transactionNumber: $transactionNumber
      maintenanceCustomerOrderNumber: $maintenanceCustomerOrderNumber
      maintenanceCustomerOrderLineNumber: $maintenanceCustomerOrderLineNumber
      issuedBy: $issuedBy
    )
  }
`;

const UPDATE_SALES_PRICE = gql`
  mutation updateInvoiceWorkOrderLineSalesPrice(
    $salesPrice: Float!
    $quotation: Int!
    $transactionNumber: Int!
    $maintenanceCustomerOrderNumber: String!
    $maintenanceCustomerOrderLineNumber: String!
    $issuedBy: String!
  ) {
    updateInvoiceWorkOrderLineSalesPrice(
      salesPrice: $salesPrice
      quotation: $quotation
      transactionNumber: $transactionNumber
      maintenanceCustomerOrderNumber: $maintenanceCustomerOrderNumber
      maintenanceCustomerOrderLineNumber: $maintenanceCustomerOrderLineNumber
      issuedBy: $issuedBy
    )
  }
`;

const Step2Row: FC<{
  invoiceLine: InvoiceLine;
  handleInvoiceLineEdit?: any;
  readOnly?: boolean;
}> = ({ invoiceLine, handleInvoiceLineEdit, readOnly = false }) => {
  const userData = useUserData();
  const [updateDiscount] = useMutation(UPDATE_DISCOUNT);
  const [updateQuantity] = useMutation(UPDATE_QUANTITY);
  const [updateSalesPrice] = useMutation(UPDATE_SALES_PRICE);

  // This is temporal, while editing. But always we want the fresh source-of-truth data it comes from data.quantity rendered
  const [quantity, setQuantity] = useState<string>(
    invoiceLine.quantity?.toString(),
  );
  const [salesPrice, setSalesPrice] = useState<string>(
    invoiceLine.salesPrice?.toString(),
  );
  const [discount, setDiscount] = useState<string>(
    invoiceLine.discountPercentage?.toString(),
  );

  // Store original values
  const [originalQuantity, setOriginalQuantity] = useState<string>(quantity);
  const [originalSalesPrice, setOriginalSalesPrice] =
    useState<string>(salesPrice);
  const [originalDiscount, setOriginalDiscount] = useState<string>(discount);
  useEffect(() => {
    // Set original values when invoiceLine changes
    setOriginalQuantity(invoiceLine.quantity?.toString());
    setOriginalSalesPrice(invoiceLine.salesPrice?.toString());
    setOriginalDiscount(invoiceLine.discountPercentage?.toString());
  }, [invoiceLine]);

  const handleQuantityChange = (value) => {
    // Replace commas with periods
    const formattedValue = value.replace(/,/g, ".");
    setQuantity(formattedValue);
  };

  const handleSalesPriceChange = (value) => {
    // Replace commas with periods
    const formattedValue = value.replace(/,/g, ".");
    setSalesPrice(formattedValue);
  };

  const handleDiscountChange = (value) => {
    // Replace commas with periods
    const formattedValue = value.replace(/,/g, ".");
    setDiscount(formattedValue);
  };

  const handleDiscountUpdate = (e: any) => {
    const newValue = e?.target?.value || "";
    if (newValue !== originalDiscount) {
      handleInvoiceLineEdit();
      updateDiscount({
        variables: {
          discountPercentage: parseFloat(newValue),
          quotation: invoiceLine.quotation,
          transactionNumber: invoiceLine.transactionNumber,
          maintenanceCustomerOrderNumber:
            invoiceLine.maintenanceCustomerOrderNumber,
          maintenanceCustomerOrderLineNumber:
            invoiceLine.maintenanceCustomerOrderLineNumber,
          issuedBy: userData.mail,
        },
      });
    }
  };

  const handleQuantityUpdate = (e: any) => {
    const newValue = e?.target?.value || "";
    
    if (newValue !== originalQuantity) {
      handleInvoiceLineEdit();
      updateQuantity({
        variables: {
          quantity: parseFloat(newValue),
          quotation: invoiceLine.quotation,
          transactionNumber: invoiceLine.transactionNumber,
          maintenanceCustomerOrderNumber:
            invoiceLine.maintenanceCustomerOrderNumber,
          maintenanceCustomerOrderLineNumber:
            invoiceLine.maintenanceCustomerOrderLineNumber,
          issuedBy: userData.mail,
        },
      });
    }
  };

  const handleSalesPriceUpdate = (e: any) => {
    const newValue = e.target.value || "";
    if (newValue !== originalSalesPrice) {
      handleInvoiceLineEdit();
      updateSalesPrice({
        variables: {
          salesPrice: parseFloat(newValue),
          quotation: invoiceLine.quotation,
          transactionNumber: invoiceLine.transactionNumber,
          maintenanceCustomerOrderNumber:
            invoiceLine.maintenanceCustomerOrderNumber,
          maintenanceCustomerOrderLineNumber:
            invoiceLine.maintenanceCustomerOrderLineNumber,
          issuedBy: userData.mail,
        },
      });
    }
  };

  return (
    <div className={styles.gridRow2}>
      <div
        style={{
          paddingTop: 12,
          fontWeight: "bold",
          textTransform: "uppercase",
          textAlign: "left",
        }}
      >
        {invoiceLine?.itemName}
      </div>
      <div
        style={{
          paddingTop: 12,
          fontWeight: "bold",
          textTransform: "uppercase",
          textAlign: "left",
        }}
      >
        {invoiceLine?.costingTypeDescription}
      </div>
      <div style={{ paddingTop: 12 }}>
        {moment(invoiceLine.transactionDate).format("DD.MM.YYYY")}
      </div>
      <div style={{ paddingTop: 4, marginRight: 20 }}>
        <InputText
          disabled={readOnly}
          className={`${styles.inputText} ${styles.inputQuantity} ${
            readOnly ? styles.inputTextReadOnly : ""
          }`}
          value={quantity}
          onBlur={handleQuantityUpdate}
          setValue={handleQuantityChange}
          placeholder="N/A"
          error={false}
          errorMessage=""
        />
        <span>{invoiceLine.unit}</span>
      </div>
      <div style={{ textAlign: "right", paddingTop: 13 }}>
        {invoiceLine?.unitPrice?.toFixed(2)}
      </div>
      <div style={{ paddingTop: 5 }}>
        <InputText
          disabled={readOnly}
          tabIndex={-1}
          className={`${styles.inputText} ${
            readOnly ? styles.inputTextReadOnly : ""
          }`}
          value={salesPrice?.toString()}
          onBlur={handleSalesPriceUpdate}
          setValue={handleSalesPriceChange}
          placeholder="N/A"
          error={false}
          errorMessage=""
        />
      </div>
      <div style={{ paddingTop: 5 }}>
        <InputText
          tabIndex={-1}
          disabled={readOnly}
          className={`${styles.inputText} ${
            readOnly ? styles.inputTextReadOnly : ""
          }`}
          value={discount}
          onBlur={handleDiscountUpdate}
          setValue={handleDiscountChange}
          placeholder="N/A"
          error={false}
          errorMessage=""
        />
      </div>

      <CurrencyFormat
        value={invoiceLine?.totalSalesPrice?.toFixed(2)}
        displayType="text"
        thousandSeparator
        prefix="NOK "
        renderText={(value) => <div style={{ paddingTop: 13 }}>{value}</div>}
      />
    </div>
  );
};

export default Step2Row;
