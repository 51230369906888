import {
  DraggableItemType,
  DroppableItemType,
  OrderUpdateEventOrderType,
  VisualColorTheme,
} from "@/types";

export const LOADING_STYLE: VisualColorTheme = {
  color: "#C4C4C4",
  background: "#F6F6F6",
};

export const STATIC_TIMELINE_ROWS = [
  { name: "INTERNAL WORK", id: "101" },
  { name: "OCTG", id: "105" },
  { name: "TAXI", id: "106" },
  { name: "BULK", id: "107" },
  { name: "BASE", id: "108" },
  { name: "PROJECT", id: "114" },
  { name: "STEELWORK SERVICES", id: "130" },
  { name: "WAREHOUSE", id: "150" },
];

export const draggableItemToOrderTypeMap: Record<
  DroppableItemType,
  OrderUpdateEventOrderType
> = {
  [DraggableItemType.Order]: "vessel",
  [DraggableItemType.WorkOrder]: "work-order",
  [DraggableItemType.GhostOrder]: "ghost-activity",
  [DraggableItemType.SubWorkOrder]: "sub-work-order",
};

export const ORDER_CARD_BORDER_WIDTH = 2;
export const ORDER_DRAGGABLE_SIDE_WIDTH = 5;
export const FINISHED_ORDER_LINE_THRESHOLD: number = 89;
export const STOPPED_ORDER_LINE_THRESHOLD: number = 69;
export const REQUESTED_WORK_ORDER_THRESHOLD: number = 10;
export const INVOICED_WORK_ORDER_THRESHOLD: number = 95;
export const STARTED_WORK_ORDER_THRESHOLD: number = 40;
