import React from "react";

import CustomSelect from "@/components/common/formElements/reactSelect";
import useGetFacilities from "@/hooks/facilities/useGetFacilities";
import logo from "@/style/img/nsg_logo.svg";
import { Facility } from "@/types";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import DateFilters from "./datefilters";
import OrderFilters from "./orderFilters";

import styles from "./index.module.scss";

const Header: React.FC = () => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const setSelectedFacility = useSettingsStore(
    (state) => state.setSelectedFacility,
  );

  const facilitiesData = useGetFacilities();

  const facilitiesOptions = facilitiesData.map((f: Facility) => {
    return { value: f.id, label: `${f.id} ${f.name.toUpperCase()}` };
  });

  // key, value pair
  const currentFacility = facilitiesOptions.find(
    (f) => f?.value === selectedFacility,
  );

  return (
    <div
      className={styles.mainWrapper}
      style={{
        backgroundColor:
          window?.DOLITTLE_ENVIRONMENT !== "prod" ? "#C8DADB" : "white",
      }}
    >
      <div className={styles.headerLogo}>
        <img src={logo} alt="NorSea Group." />
      </div>

      <div className={`${styles.headerFilters}`}>
        <div className={`${styles.selectors} flex-center`}>
          <CustomSelect
            value={currentFacility}
            onChange={(value) => {
              if (value?.value) {
                setSelectedFacility(value?.value);
                window.location.reload(); // TODO: is possible to avoid full refresh?
              }
            }}
            placeholder="Select base"
            options={facilitiesOptions}
          />
        </div>
        <DateFilters />
        <OrderFilters />
      </div>
    </div>
  );
};

export default Header;
