import { gql } from "@apollo/client";

export const UPDATE_VESSEL_NOTE = gql`
  mutation updateVesselNote(
    $facilityId: String!
    $issuedBy: String!
    $vesselNoteId: String!
    $text: String!
  ) {
    updateVesselNote(
      facilityId: $facilityId
      issuedBy: $issuedBy
      vesselNoteId: $vesselNoteId
      text: $text
    )
  }
`;

export const CREATE_VESSEL_NOTE = gql`
  mutation createVesselNote(
    $facilityId: String!
    $issuedBy: String!
    $vesselId: String!
    $text: String!
  ) {
    upsertVesselNote(
      description: ""
      facilityId: $facilityId
      issuedBy: $issuedBy
      text: $text
      vesselId: $vesselId
    )
  }
`;

export const GET_VESSEL_NOTES = gql`
  query getNotes($vesselId: String!) {
    vesselActivity(vesselId: $vesselId) {
      vesselNotes {
        description
        entryDate
        id
        lastModified
        noteType
        text
        textIdentity
        modifiedBy
      }
    }
  }
`;

export const UPDATE_WORK_ORDER_NOTE = gql`
  mutation updateWorkOrderNote(
    $facilityId: String!
    $issuedBy: String!
    $orderNoteId: String!
    $text: String!
  ) {
    updateWorkOrderNote(
      facilityId: $facilityId
      issuedBy: $issuedBy
      orderNoteId: $orderNoteId
      text: $text
    )
  }
`;

export const CREATE_WORK_ORDER_NOTE = gql`
  mutation createWorkOrderNote(
    $facilityId: String!
    $issuedBy: String!
    $workOrderId: String!
    $text: String!
  ) {
    upsertWorkOrderNote(
      description: "Comment"
      facilityId: $facilityId
      issuedBy: $issuedBy
      text: $text
      workOrderId: $workOrderId
    )
  }
`;

export const GET_WORK_ORDER_NOTES = gql`
  query getNotes($workOrderId: String!) {
    workOrderById(workOrderId: $workOrderId) {
      id
      workOrderNotes {
        description
        entryDate
        id
        lastModified
        noteType
        text
        textIdentity
        modifiedBy
        specifyDescription
      }
    }
  }
`;

export const UPSERT_GHOST_ACTIVITY_NOTE = gql`
  mutation upsertGhostActivityNote(
    $facilityId: String!
    $issuedBy: String!
    $ghostActivityId: UUID!
    $ghostActivityNoteId: UUID
    $text: String!
  ) {
    upsertGhostActivityNote(
      facilityId: $facilityId
      issuedBy: $issuedBy
      ghostActivityId: $ghostActivityId
      ghostActivityNoteId: $ghostActivityNoteId
      text: $text
    )
  }
`;

export const GET_GHOST_ACTIVITY_NOTES = gql`
  query ghostActivityNotes($ghostActivityId: UUID!) {
    ghostActivityNotes(ghostActivityId: $ghostActivityId) {
      id
      entryDate
      lastModified
      modifiedBy
      text
    }
  }
`;
