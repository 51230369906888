import { gql, useMutation } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import React, { FC, memo, useEffect, useMemo, useState } from "react";

import Checkbox from "@/components/common/formElements/checkbox/checkbox";
import { normalizeAssignment } from "@/helpers/normalizeCapabilityRequirements";
import {
  OrderStatusColors,
  OrderStatusEnum,
  OrderType,
} from "@/helpers/OrderStatusColors";
import trash from "@/style/img/icons/trash.svg";
import { WorkOrderLineTypes } from "@/types";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

import OperationLineDates from "./operationLineDates";

import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";
import { FINISHED_ORDER_LINE_THRESHOLD } from "@/helpers/constants"; // 88

type Ids = {
  id: string;
  operatorId: string;
  laborChargeCode: string;
  cardNumber: string;
};
type Props = {
  data: WorkOrderLineTypes;
  setSelectedIds: React.Dispatch<React.SetStateAction<Ids[]>>;
  selectedIds: Ids[];
  setResourcesPanelActive: (workOrderLineId?: string) => void;
  removeWorkOrderLine: (id: string) => void;
  active: boolean;
  isDisturbanceEnabled: boolean;
};

const DELETE_LINE = gql`
  mutation deleteWorkOrderLine(
    $facilityId: String!
    $issuedBy: String!
    $workOrderLineId: String!
  ) {
    deleteWorkOrderLine(
      facilityId: $facilityId
      issuedBy: $issuedBy
      workOrderLineId: $workOrderLineId
    )
  }
`;

const EachOperationLine: FC<Props> = ({
  data,
  setResourcesPanelActive,
  selectedIds,
  setSelectedIds,
  removeWorkOrderLine,
  active,
  isDisturbanceEnabled,
}) => {
  const userData = useUserData();
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);

  const [deleteWorkOrderLine] = useMutation(DELETE_LINE);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!isEmpty(selectedIds)) {
      selectedIds.map((el) => el.id === data.id && setChecked(true));
    } else {
      setChecked(false);
    }
  }, [data.id, selectedIds]);

  const handleDeleteWorkOrderLine = () => {
    removeWorkOrderLine(data.id);
    deleteWorkOrderLine({
      variables: {
        facilityId: selectedFacility,
        issuedBy: userData.mail,
        workOrderLineId: data.id,
      },
    });
  };

  const handleCheckItem = () => {
    const newArr: typeof selectedIds = [...selectedIds];
    if (newArr.find((el) => data.id === el.id)) {
      newArr.splice(
        newArr.findIndex((i) => i.id === data.id),
        1,
      );
      setSelectedIds(newArr);
    } else if (
      data?.assignment?.operator?.id &&
      data?.assignment?.capabilityTypeNumber
    ) {
      setSelectedIds([
        ...selectedIds,
        {
          id: data.id,
          operatorId: data.assignment.operator.id || "",
          laborChargeCode:
            data.assignment.capabilityTypeNumber?.toString() || "",
          cardNumber: data.assignment.operator.cardNumber || "",
        },
      ]);
    }
    setChecked(!checked);
  };

  // const capabilityRequirements = useMemo(
  //   () => normalizeCapability(data.capabilityRequirements?.[0]),
  //   [data],
  // );

  const isFinished = (orderLineStatus: string) => {
    return parseInt(orderLineStatus) > FINISHED_ORDER_LINE_THRESHOLD;
  };

  const capabilityRequirements = useMemo(
    () => normalizeAssignment(data.assignment),
    [data],
  );
  return (
    <div
      className={
        active
          ? `${styles.operationContainer} ${styles.active}`
          : styles.operationContainer
      }
      key={data.id}
    >
      <div
        tabIndex={0}
        role="button"
        onKeyDown={() => null}
        onClick={(e) => {
          if (
            !(e.target as Element).className?.includes("stoppropagation") &&
            !(e.target as Element).className?.includes("MuiInputBase") &&
            !isFinished(data.status)
          ) {
            setResourcesPanelActive(data.id);
          }
        }}
        className={styles.operation}
      >
        <div>
          <Checkbox
            checked={isFinished(data.status) ? false : checked}
            onChange={handleCheckItem}
            label=""
            disabled={
              capabilityRequirements.assigned === "Unassigned" ||
              isFinished(data.status)
            }
          />
        </div>
        <div className={styles.operator}>
          <h5
            className={textStyles.primaryText}
          >{`${data.capability} ${data.description}`}</h5>
          <div
            style={{
              backgroundColor: `${OrderStatusColors.getBackgroundColor(
                data.derivedStatus as OrderStatusEnum,
                OrderType.OPERATION_LINE,
              )}`,
            }}
            className={styles.operatorName}
          >
            {capabilityRequirements.requiresEquipmentOperator && (
              <>
                <span
                  style={
                    !data.assignment?.workCenter ? { color: "#BB0000" } : {}
                  }
                  className={textStyles.primaryText}
                >
                  {capabilityRequirements.equipment}
                </span>
                <span className={textStyles.primaryText}> + </span>
              </>
            )}
            <span
              style={
                capabilityRequirements.assigned === "Unassigned"
                  ? { color: "#BB0000" }
                  : {}
              }
              className={textStyles.primaryText}
            >
              {capabilityRequirements.assigned}
            </span>
          </div>
          {!isEmpty(data.timestampOperations) &&
            data.timestampOperations.map((t) => (
              <OperationLineDates
                isDisabled={isFinished(data.status)}
                key={t.id}
                dates={{
                  startDate: t.startDate,
                  stopDate: t.stopDate,
                }}
                disturbance={t.disturbance}
                timestampId={t.id}
                operatorId={data.assignment?.operator?.id}
              />
            ))}
        </div>
        <div className={styles.operationActions}>
          <button
            disabled={isFinished(data.status)}
            onClick={handleDeleteWorkOrderLine}
            className={`${styles.deleteButton} stoppropagation`}
            type="button"
            title={
              !isFinished(data.status)
                ? "Delete Work Order Line"
                : "Approved Work Order Line cannot be deleted"
            }
          >
            <img className="stoppropagation" src={trash} alt="trash" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default memo(EachOperationLine);
