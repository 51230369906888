import { gql, useQuery } from "@apollo/client";

import { WorkOrdertype } from "@/types";

export const WORK_ORDER_TYPE_QUERY = gql`
  query {
    workOrderTypes {
      id
      name
      status
      __typename
    }
  }
`;

const useGetWorkOrderTypes = (): WorkOrdertype[] => {
  const { data } = useQuery(WORK_ORDER_TYPE_QUERY);
  const workOrderTypes: WorkOrdertype[] = data?.workOrderTypes || [];
  return [...workOrderTypes].sort((a, b) => parseInt(a.id) - parseInt(b.id));
};
export default useGetWorkOrderTypes;
