// import take from "lodash/take";
// import moment from "moment-timezone";
import React, { FC, memo } from "react";

// import InfiniteScroll from "react-infinite-scroller";
import { WorkOrder } from "@/types";

import ListItem from "./searchListItem";

import styles from "./index.module.scss";

type Props = {
  orders: WorkOrder[] | undefined;
};
const FilteredList: FC<Props> = ({ orders }) => {
  const workOrdersFromSearch = orders?.map((order) => (
    <ListItem key={order.id} data={order} />
  ));
  return (
    <div className={styles.filteredList}>{orders && workOrdersFromSearch}</div>
  );
};
export default memo(FilteredList);
