import moment, { Moment } from "moment-timezone";
import React, { useState } from "react";

import TimelineDatePicker from "@/components/common/formElements/timelineDatePicker";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import styles from "./index.module.scss";
import buttons from "@/style/buttons.module.scss";

const DateFilters: React.FC = () => {
  const selectedDate = useSettingsStore((state) => state.selectedDate);
  const setSelectedDate = useSettingsStore((state) => state.setSelectedDate);

  if (!selectedDate) {
    setSelectedDate(moment().toDate());
  }

  return (
    <div className={styles.dateFilters}>
      <div className={`${styles.datePickerWrapper} flex-center`}>
        <TimelineDatePicker dateValue={moment(selectedDate)} />
      </div>
      <div className={`flex-center ${styles.dateIndicators}`}>
        <button
          onClick={(e) => {
            setSelectedDate(moment().toDate());
            e.currentTarget.blur();
          }}
          type="button"
          className={buttons.mainButton}
        >
          TODAY
        </button>
      </div>
    </div>
  );
};

export default DateFilters;
