import {
  FINISHED_ORDER_LINE_THRESHOLD,
  STOPPED_ORDER_LINE_THRESHOLD,
} from "@/helpers/constants";
import { normalizeAssignment } from "@/helpers/normalizeCapabilityRequirements";
import {
  OrderStatusColors,
  OrderStatusEnum,
  OrderType,
} from "@/helpers/OrderStatusColors";
import re_open_wo_line from "@/style/img/re_open_wo_line.svg";
import { WorkOrderLineTypes } from "@/types";
import { isEmpty } from "lodash";
import { memo, useEffect, useMemo } from "react";
import TimestampRow, {
  CostingType,
  LaborCode,
  TimestampInInvoices,
} from "./InvoiceTableStep1Row";

import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

const WorkOrderLine: React.FC<{
  orderLineData: WorkOrderLineTypes;
  handleRemoveTimestamp: (arg0: string) => void;
  handleSelectedChange: (arg0: string, arg1: boolean) => void;
  reopenWorkOrderLine: (lineId: string) => void;
  allLaborCodes: LaborCode[];
  allCostingTypes: CostingType[];
  awaitingApproval: boolean;
}> = ({
  orderLineData,
  handleSelectedChange,
  handleRemoveTimestamp,
  reopenWorkOrderLine,
  allLaborCodes,
  allCostingTypes,
  awaitingApproval,
}) => {
  const capabilityRequirements = useMemo(
    () => normalizeAssignment(orderLineData.assignment),
    [orderLineData],
  );

  useEffect(() => {
    if (
      parseInt(orderLineData.status, 10) > STOPPED_ORDER_LINE_THRESHOLD &&
      parseInt(orderLineData.status, 10) < FINISHED_ORDER_LINE_THRESHOLD
    ) {
      const checkbox: HTMLInputElement = document.getElementById(
        orderLineData.id,
      );
      checkbox.checked = true;
      handleSelectedChange(orderLineData.id, true);
    }
    if (parseInt(orderLineData.status, 10) > FINISHED_ORDER_LINE_THRESHOLD) {
      handleSelectedChange(orderLineData.id, false);
    }
  }, [orderLineData.status]);

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        {/* To be reinstated when  backend can do sufficient checks on MCO/billing status*/}
        {/* {parseInt(orderLineData.status) > FINISHED_ORDER_LINE_THRESHOLD && (
          <button
            className={styles.reopenButton}
            type="button"
            title="Re-open"
            onClick={() => {
              reopenWorkOrderLine(orderLineData.id);
            }}
          >
            <img src={re_open_wo_line} alt="re-open-line" />
          </button>
        )} */}
        {parseInt(orderLineData.status) < FINISHED_ORDER_LINE_THRESHOLD &&
          parseInt(orderLineData.status) > STOPPED_ORDER_LINE_THRESHOLD && (
            <input
              id={orderLineData.id}
              type="checkbox"
              disabled={awaitingApproval}
              className={`approval-selector`}
              onChange={(e) => {
                handleSelectedChange(orderLineData.id, e.target.checked);
              }}
              style={{
                width: "20px",
                marginLeft: "0.5rem",
              }}
            />
          )}
        <div>
          {`${orderLineData.capability}  ${orderLineData?.description} ${
            capabilityRequirements?.requiresEquipmentOperator
              ? " + OPERATOR"
              : ""
          }`}
        </div>
        <div
          style={{
            backgroundColor: `${OrderStatusColors.getBackgroundColor(
              orderLineData.derivedStatus as OrderStatusEnum,
              OrderType.OPERATION_LINE,
            )}`,
          }}
          className={styles.operatorName}
        >
          {capabilityRequirements.requiresEquipmentOperator && (
            <>
              <span className={textStyles.primaryText}>
                {capabilityRequirements.equipment}
              </span>
              <span className={textStyles.primaryText}> + </span>
            </>
          )}
          <span
            style={
              capabilityRequirements.assigned === "Unassigned"
                ? { color: "#BB0000" }
                : {}
            }
            className={textStyles.primaryText}
          >
            {capabilityRequirements.assigned}
          </span>
        </div>
      </div>
      {!isEmpty(orderLineData?.timestampOperations) && (
        <div className={`${styles.gridRow} ${styles.gridRowStep1}`}>
          <div className={styles.timestampDates} style={{ marginLeft: "15px" }}>
            Timestamps
          </div>
          <div>Used labour</div>
          <div>Labor charge code</div>
          <div>Costing type</div>
          <div className={styles.deleteButton}>Delete</div>
        </div>
      )}
      {orderLineData?.timestampOperations?.map((row: TimestampInInvoices) => {
        return (
          <TimestampRow
            key={row.id}
            timestamp={row}
            allCostingTypes={allCostingTypes}
            allLaborCodes={allLaborCodes}
            operatorId={orderLineData.assignment?.operator?.id}
            isDisabled={
              parseInt(orderLineData.status) > FINISHED_ORDER_LINE_THRESHOLD ||
              awaitingApproval
            }
            removeTimestamp={handleRemoveTimestamp}
          />
        );
      })}
    </div>
  );
};
export default memo(WorkOrderLine);
